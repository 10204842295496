<template>
  <main class="kb-main" id="kb-helpdesk">
    <lxp-main-header
        :show-back="headerOption.showBack"
        :show-breadcrumb="headerOption.showBreadcrumb"
        :show-title="headerOption.showTitle" title="자료실"/>
    <!-- main-content -->
    <div class="main-content min-component">
      <template v-if="!headerOption.isSearch">
        <div class="ref-list-container">
          <div class="list-top">
            <div v-if="paging.totalCount > 0" class="top-column ps-6">
              <p class="title text-muted">총 {{ paging.totalCount }}건</p>
            </div>
            <div class="top-column">
              <div class="kb-form-search">
                <div class="kb-form-search-field">
                  <input type="text" class="kb-form-search-input" v-model="search.postTitle" @keyup.enter="clickSearch" placeholder="자료실 검색" />
                  <button class="kb-form-search-btn"><i class="icon-search">검색</i></button>
                </div>
              </div>
            </div>
          </div>

          <div class="board-list-container">
            <ul class="board-list">
              <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
                <article class="board-row">
                  <router-link :to="{ name: 'HelpLibView', params: {comPostSn: item.comPostSn} }" class="board-link"></router-link>
<!--                  <div class="board-column column-index"><em class="text text-gold">필독</em></div>-->
                  <div class="board-column column-index">{{idx+1+(paging.pageSize*(paging.pageNo-1))}}</div>
                  <div class="board-column column-file"><a href="javascript:" class="file-link" v-if="item.postFileAtchYn === 'Y'"><i class="icon-file"></i></a></div>
                  <div class="board-column column-title"><span class="title">{{item.postTitle}}</span><i v-if="item.newLib === 'Y'" class="icon-new"></i></div>
                  <div class="board-column column-date"><span class="text">{{timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm')}}</span></div>
                  <div class="board-column column-hit"><span class="text">{{item.inqCnt}} 조회</span></div>
                </article>
              </li>
            </ul>
            <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="search-container">
          <!-- search-container > search-field -->
          <div class="search-field">
            <form @submit.prevent>
              <div class="kb-form-search">
                <div class="kb-form-search-field">
                  <input type="text" class="kb-form-search-input" v-model.trim="search.postTitle" @keyup.enter="clickSearch">
                  <button class="kb-form-search-btn"><i class="icon-search">검색</i></button>
                  <button class="kb-form-search-delete" @click="search.postTitle=''"><i class="icon-delete">삭제</i></button>
                </div>
              </div>
            </form>
          </div>
          <!-- search-container > search-result -->
          <div class="search-result">
            <div class="ref-list-container" v-if="items && items.length > 0">
              <div class="list-top">
                <div v-if="paging.totalCount > 0" class="top-column ps-6">
                  <p class="title text-muted">총 {{ paging.totalCount }}건</p>
                </div>
              </div>

              <div class="board-list-container">
                <ul class="board-list">
                  <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
                    <article class="board-row">
                      <router-link :to="{name: 'HelpLibView', params: {comPostSn: item.comPostSn}}" class="board-link"></router-link>
<!--                      <div class="board-column column-index"><em class="text text-gold">필독</em></div>-->
                      <div class="board-column column-index">{{idx+1+(paging.pageSize*(paging.pageNo-1))}}</div>
                      <div class="board-column column-file"><a href="javascript:" class="file-link" v-if="item.postFileAtchYn === 'Y'"><i class="icon-file"></i></a></div>
                      <div class="board-column column-title">
                        <span class="title">{{item.postTitle}}</span>
                        <i v-if="item.newLib === 'Y'" class="icon-new"></i>
                      </div>
                      <div class="board-column column-date"><span class="text">{{timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm')}}</span></div>
                      <div class="board-column column-hit"><span class="text">{{item.inqCnt}} 조회</span></div>
                    </article>
                  </li>
                </ul>
                <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
              </div>
            </div>

            <!-- result-empty -->
            <div class="result-empty" v-else>
              <img src="../../../assets/lxp/images/common/img_empty.png">
              <p class="text">검색결과가 없어요</p>
            </div>
            <!-- //result-empty -->
          </div>
          <!-- //search-container > search-result -->
        </div>
      </template>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import {onBeforeMount, onMounted, reactive, ref, watch, computed} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {
  getItems,
  getPaging,
  initPaging,
  lengthCheck,
  setParamsByQueryString,
  timestampToDateFormat,
} from '@/assets/js/util';
import {ACT_GET_HELP_LIB_LIST} from '@/store/modules/help/help';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {libTyCdDcds} from "@/assets/js/modules/help/help-common";

export default {
  name: 'HelpLib',
  components: {CommonPaginationFront, LxpMainHeader},
  setup(){
    const isCategoryActive = ref(false);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route));
    const items = ref([]);

    const search = reactive({
      postTitle: ''
    });

    const headerOption = ref({
      showBack: false,
      showBreadcrumb: true,
      showTitle: true,
      isSearch: false
    });

    const nowDt = computed(() => timestampToDateFormat(new Date(), 'yyyy-MM-dd'));
    const getHelpLibList = () => {
      store.dispatch(`help/${ACT_GET_HELP_LIB_LIST}`, {
        postTitle: search.postTitle,
        libTyCdDcd: libTyCdDcds.LIB_TY_CD_LIB,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          // 새 게시물인지 날짜로 비교
          items.value.map((x) => {
            let diff = new Date(nowDt.value) - new Date(timestampToDateFormat(x.regDt, 'yyyy-MM-dd'));
            let month = new Date(diff).getMonth() > 0 ? new Date(diff).getMonth() * 30 : 0;
            let date = new Date(diff).getDate();
            if(month + date <= 7) x.newLib = 'Y';
            else x.newLib = 'N';
          })
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const pagingFunc = (pageNo, pageSize) => {
      const query = {
        pageNo: pageNo,
        postTitle: search.postTitle
      };
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      router.push({query: query});
    };

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const clickSearch = () => {
      headerOption.value.showBack = true;
      headerOption.value.showBreadcrumb = false;
      headerOption.value.showTitle = false;
      headerOption.value.isSearch = true;

      pagingFunc(1, 10);
    };

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'HelpLib', paging, search, true);
      if (!route.query.postTitle) {
        headerOption.value.showBack = false;
        headerOption.value.showBreadcrumb = true;
        headerOption.value.showTitle = true;
        headerOption.value.isSearch = false;
      }
      getHelpLibList();
    });

    onBeforeMount(() => {
      // CommonPagination 컴포넌트가 로드되기 전에 paging을 고쳐야 query string에 있는 pageSize가 셀렉트 박스에 반영됨.
      setParamsByQueryString(route, 'HelpLib', paging, search, true); // 새로고침을 위한 작업
    });

    onMounted(() => {
      getHelpLibList();
    });

    return{
      isCategoryActive,
      items,
      search,
      paging,
      pagingFunc,
      clickSearch,
      getDateFormat,
      timestampToDateFormat,

      headerOption
    }

  }
}
</script>